<template>
  <form class="view-admin-new-class" @submit.prevent="submit">
    <form-group
      id="class"
      v-model="form.class_id"
      label="Turma que a atividade será destinada*"
      type="text"
      select
      :options="classOptions"
      placeholder="Escolha a turma desejada"
      :errors="errors.class_id"
      class="mb-4"
    />

    <form-group
      id="module_id"
      v-model="form.module_id"
      label="Módulo que a atividade pertence*"
      select
      :options="moduleOptions"
      placeholder="Escolha módulo desejado"
      :errors="errors.module_id"
      class="mb-4"
    />

    <form-group
      id="title"
      v-model="form.title"
      label="Enunciado da atividade*"
      textarea
      placeholder="Digite a explicação da atividade"
      :errors="errors.name"
      class="mb-4"
    />

    <form-group
      id="response_type"
      v-model="form.response_type"
      label="Qual o formato de resposta desta atividade?"
      select
      :options="responseTypeOptions"
      placeholder="Escolha formato desejado"
      :errors="errors.response_type"
      class="mb-4"
    />

    <form-group
      v-model="form.deadline"
      id="deadline"
      isDate
      label="Até quando os alunos poderão enviar esta atividade?"
      placeholder="Ex: 14/10/2023"
      :errors="errors.deadline"
    />

    <div
      class="row align-items-center justify-content-between mt-5 pt-4 pt-lg-5"
    >
      <div class="col-lg-4 order-2 order-lg-1">
        <button
          type="button"
          class="btn btn-outline-secondary btn-block"
          @click="$router.go(-1)"
        >
          Voltar
        </button>
      </div>
      <div class="col-lg-4 order-1 order-lg-2">
        <button type="submit" class="btn btn-primary btn-block">
          <loading :show="submitLoading">
            Salvar
          </loading>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { formFields, clearObject } from "@/functions/helpers";
import { mapState } from "vuex";
import get from "lodash/get";

export default {
  mounted() {
    this.$store.dispatch("content/getModules").then();
    this.$store.dispatch("classes/get").then();

    if (this.$route.params.id) {
      this.pageLoading = true;
      this.$store
        .dispatch("homeworks/getHomework", this.$route.params.id)
        .then(data => {
          this.form = data;
        })
        .finally(() => {
          this.pageLoading = false;
        });
    }
  },
  computed: {
    ...mapState("content", ["modules"]),
    ...mapState("classes", ["classes"]),
    responseTypeOptions() {
      return [
        { id: "text", name: "Em texto direto na plataforma" },
        { id: "doc", name: "Em PDF ou word" },
        { id: "slides", name: "Em slides" },
        { id: "video", name: "Em video" }
      ];
    },
    moduleOptions() {
      return this.modules.map(({ id, name }) => ({ id, name }));
    },
    classOptions() {
      return this.classes.map(({ id, name }) => ({ id, name }));
    }
  },
  data() {
    return {
      ...formFields(
        ["title", "deadline", "class_id", "module_id", "response_type"],
        {
          class_id: get(this.$route.query, "class", "")
        }
      ),
      pageLoading: false,
      submitLoading: false
    };
  },
  methods: {
    submit() {
      if (this.submitLoading) return;

      if (!this.form?.class_id || !this.form?.class_id.length) {
        this.errors.class_id = [
          "Por favor, escolha para qual classe destina-se esta atividade"
        ];
        this.$message.warning(
          "Por favor, escolha para qual classe destina-se esta atividade"
        );
        return;
      }

      if (!this.form?.module_id || !this.form?.module_id.length) {
        this.errors.module_id = [
          "Por favor, escolha para qual módulo destina-se esta atividade"
        ];
        this.$message.warning(
          "Por favor, escolha para qual módulo destina-se esta atividade"
        );
        return;
      }

      if (!this.form?.title || !this.form?.title.length) {
        this.errors.title = ["Por favor, digite a explicação da atividade"];
        this.$message.warning("Por favor, digite a explicação da atividade");
        return;
      }

      if (!this.form?.response_type || !this.form?.response_type.length) {
        this.errors.response_type = [
          "Por favor, escolha o tipo de resposta desta atividade"
        ];
        this.$message.warning(
          "Por favor, escolha o tipo de resposta desta atividade"
        );
        return;
      }

      if (!this.form?.deadline || !this.form?.deadline.length) {
        this.errors.deadline = [
          "Por favor, indique a data final desta atividade"
        ];
        this.$message.warning(
          "Por favor, indique a data final desta atividade"
        );
        return;
      }

      const action = this.$route.params.id ? "homeworks/put" : "homeworks/post";

      const payload = { ...this.form, created_at: new Date().toISOString() };

      let msg = "Atividade adicionada com sucesso!";

      if (this.$route.params.id) {
        msg = "Atividade editada com sucesso!";
        payload.id = this.$route.params.id;
      }

      this.submitLoading = true;

      this.$store
        .dispatch(action, clearObject(payload))
        .then(() => {
          this.$message.success(msg);
          this.$router.push({
            name: "admin-homeworks"
          });
        })
        .catch(e => {
          this.$message.error("Não foi possível cadastrar a nova atividade");
        })
        .finally(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-admin-new-class {
}
</style>
